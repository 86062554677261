// Overrides default CSS for react-date-range lib

.rdrDateRangePickerWrapper {
  background-color: $background;
  border: 1px solid rgba(255, 255, 255, 0.35);
  border-radius: 4px;
  overflow: hidden;
}

.rdrDefinedRangesWrapper {
  background-color: $background;
  border: none;
  width: 100px;
}

.rdrStaticRanges {
  background-color: $background;
  width: 100px;
}

.rdrStaticRange {
  background-color: $background;
  color: rgba(255, 255, 255, 1);
  border: none;

  &:hover,
  &:focus {
    background-color: $primary  !important;
    color: $background  !important;
  }

  &>span {

    &:hover,
    &:focus {
      background-color: $primary  !important;
      color: $background  !important;
    }
  }
}

.rdrStaticRangeLabel {

  &:hover,
  &:focus {
    background-color: $primary  !important;
  }
}

button.rdrStaticRange {

  &:hover,
  &:focus {
    background-color: $primary  !important;
  }
}

.rdrCalendarWrapper {
  background-color: $background;
}

.rdrDateDisplayWrapper {
  background-color: $background;
}

.rdrDateInput {
  background-color: $background;
  border: 1px solid rgba(179, 179, 179);

  &>input {
    color: #ffffff;
    height: 38px;
    min-height: 38px;
    max-height: 38px;
    font-size: 14px;
    font-weight: 300;
  }
}

.rdrDateDisplayItemActive {
  border-color: $primary;
}

.rdrMonthAndYearPickers select {
  background: url('data:image/svg+xml;utf8,<svg version=%271.1%27 viewBox=%270 0 20 20%27 width=%2720%27 height=%2720%27 aria-hidden=%27true%27 xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27><path d=%27M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z%27 style=%27fill: rgb(190, 190, 190);%27/></svg>');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 8px);
  background-position-y: 50%;
  background-origin: padding-box;

  &:hover {
    background: url('data:image/svg+xml;utf8,<svg version=%271.1%27 viewBox=%270 0 20 20%27 width=%2720%27 height=%2720%27 aria-hidden=%27true%27 xmlns=%27http://www.w3.org/2000/svg%27 xmlns:xlink=%27http://www.w3.org/1999/xlink%27><path d=%27M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z%27 style=%27fill: rgb(160, 160, 160);%27/></svg>');
    background-repeat: no-repeat;
    background-position-x: calc(100% - 8px);
    background-position-y: 50%;
    background-origin: padding-box;
  }
}

.rdrMonthPicker {
  border: 1px solid rgba(160, 160, 160);
  border-radius: 4px;

  &:hover {
    border-color: rgb(179, 179, 179);
  }

  &>select {
    color: #ffffff;
    border: none;
    text-align: center;
    height: 38px;
    min-height: 38px;
    padding-left: 12px;
    padding-top: 8px;
    font-size: 14px;
    font-weight: 300;

    &>option {
      background-color: $background;
      border: none;
      text-align: center;
      height: 38px;
      min-height: 38px;
      line-height: 38px;
      padding: 8px 12px;
      font-size: 14px;
      font-weight: 300;
    }
  }
}

.rdrYearPicker {
  border: 1px solid rgba(160, 160, 160);
  border-radius: 4px;

  &:hover {
    border-color: rgb(179, 179, 179);
  }

  &>select {
    color: #ffffff;
    border: none;
    text-align: center;
    height: 38px;
    min-height: 38px;
    padding-left: 12px;
    padding-top: 8px;
    font-size: 14px;
    font-weight: 300;

    &>option {
      background-color: $background;
      border: none;
      text-align: center;
      height: 38px;
      min-height: 38px;
      line-height: 38px;
      padding: 8px 12px;
      font-size: 14px;
      font-weight: 300;
    }
  }
}

.rdrInputRange {
  display: none;
}

.rdrDayNumber>span {
  color: #ffffff;
}

.rdrEndEdge+.rdrDayNumber>span {
  color: $background;
}

.rdrInRange {
  color: $background;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span {
  color: $background;
}

.rdrDay:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span {
  color: $background;

  &:after {
    background-color: $background;
  }
}

.rdrDayPassive>.rdrDayNumber>span {
  color: rgba(255, 255, 255, 0.2);
}

.rdrDayToday .rdrDayNumber span {
  font-weight: 300;
  font-size: 12px;

  &:after {
    background: $primary;
  }
}

.rdrDayDisabled {
  background-color: $background;

  & .rdrDayNumber>span {
    color: rgba(255, 255, 255, 0.2);
  }
}

.rdrPprevButton,
.rdrNextButton {
  background-color: $background;
  border: 1px solid rgba(160, 160, 160);

  &:hover {
    background-color: $background;
    border-color: rgb(179, 179, 179);
  }
}

.rdrPprevButton>i {
  border-right-color: white;
}

.rdrNextButton>i {
  border-left-color: white;
}
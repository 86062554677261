.accordion-button:not(.collapsed) {
  color: rgba(255, 255, 255, 0.75);

  &:after {
    background-image: var(--bs-accordion-btn-icon);
  }

  &:hover {
    color: #ffffff;
  }
}

.accordion-button:hover {
  color: #ffffff;
}
// Rest are set in template, here we extend that number of backings

.bg-cover {

  &-7:after {
    background-image: url(../../public/assets/css/images/cover-7.jpg)
  }

  &-8:after {
    background-image: url(../../public/assets/css/images/cover-8.jpg)
  }

  &-9:after {
    background-image: url(../../public/assets/css/images/cover-9.jpg)
  }

  &-10:after {
    background-image: url(../../public/assets/css/images/cover-10.jpg)
  }

  &-11:after {
    background-image: url(../../public/assets/css/images/cover-11.jpg)
  }

  &-12:after {
    background-image: url(../../public/assets/css/images/cover-12.jpg)
  }

  &-13:after {
    background-image: url(../../public/assets/css/images/cover-13.jpg)
  }

  &-14:after {
    background-image: url(../../public/assets/css/images/cover-14.jpg)
  }

  &-15:after {
    background-image: url(../../public/assets/css/images/cover-15.jpg)
  }

  &-16:after {
    background-image: url(../../public/assets/css/images/cover-16.jpg)
  }
}